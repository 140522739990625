<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-m">{{ $metaInfo.title }}</h1>

    <!-- Фильтры -->
    <div
      v-if="list && list.length"
      class="panel radius-m mb-l">
      <div class="row">
        <div class="col-sm-6 col-xs-12 mb-m">
          <label
            class="form-label text-bold text-size-s"
            for="profile_search"
            >Искать по названию</label
          >
          <BaseInput
            id="profile_search"
            v-model.trim="search"
            name="profile_search" />
        </div>

        <div class="col-sm-6 col-xs-12">
          <label class="form-label text-bold text-size-s"
            >Искать по трекам</label
          >
          <BaseSelect
            v-model="selectedTracks"
            :options="tracksOptions"
            label="title"
            track-by="id"
            placeholder="Выберите треки"
            :multiple="true"
            :close-on-select="false">
            <template #selection="{ values, isOpen }">
              <div v-if="values.length === 1 && !isOpen">
                {{ values[0].title }}
              </div>
              <div v-else-if="values.length && !isOpen">
                Выбрано {{ values.length }}
              </div>
            </template>

            <template #noResult>Такого трека нет в списке</template>
          </BaseSelect>
          <div class="select-reset text-size-s">
            <a
              v-if="selectedTracks.length"
              href="#"
              class="link link--pseudo"
              @click.prevent="selectedTracks = []"
              >Очистить поле</a
            >
            &nbsp;
          </div>
        </div>
      </div>

      <div>
        <label class="form-label text-bold text-size-s"
          >Искать по компетенциям</label
        >
        <BaseSelect
          v-model="selectedRoutes"
          :options="routesOptions"
          label="title"
          track-by="id"
          placeholder="Выберите компетенции"
          :multiple="true"
          :close-on-select="false">
          <template #selection="{ values, isOpen }">
            <div v-if="values.length === 1 && !isOpen">
              {{ values[0].title }}
            </div>
            <div v-else-if="values.length && !isOpen">
              Выбрано {{ values.length }}
            </div>
          </template>

          <template #noResult>Такой компетенции нет в списке</template>
        </BaseSelect>
        <div class="select-reset text-size-s">
          <a
            v-if="selectedRoutes.length"
            href="#"
            class="link link--pseudo"
            @click.prevent="selectedRoutes = []"
            >Очистить поле</a
          >
        </div>
      </div>
    </div>

    <!-- Карточки -->
    <div class="row cards-list">
      <div
        v-for="profile in filteredList"
        :key="profile.id"
        class="col-sm-6 col-xs-12 cards-list__item">
        <ProfileCard
          :id="profile.id"
          class="cards-list__card"
          :icon="profile.icon"
          :title="profile.title"
          :link-to="{
            name: 'mentor-profile',
            params: {
              id: profile.id,
            },
          }"
          :label="profile.profileType"
          :tags="profile.routes"
          :rsosh-level="profile.rsosh_level"
          :detail-url="profile.detail_url">
          <p v-if="profile.short_description">
            {{ profile.short_description }}
          </p>
          <template #footer>
            <BaseButton
              v-if="selectedProfiles[profile.id]"
              :id="`deslect-profile-${profile.id}`"
              class="select-profile-btn"
              theme="primary-border"
              block
              :disabled="pendingProfiles[profile.id]"
              @click.prevent="handleRefuseProfile(profile.id)">
              <span class="btn-inner"
                >В избранном <BaseIcon glyph="done"
              /></span>
            </BaseButton>
            <BaseButton
              v-else
              :id="`select-profile-${profile.id}`"
              class="select-profile-btn"
              block
              :disabled="pendingProfiles[profile.id]"
              @click.prevent="handleSelectProfile(profile.id)"
              >добавить в избранное</BaseButton
            >
          </template>
        </ProfileCard>
      </div>
    </div>

    <div v-if="hasFilters && !filteredList.length">
      <p>
        По заданным параметрам, ничего не найдено.
        <a
          href="#"
          class="link link--pseudo"
          @click.prevent="dropFilters"
          >Сбросить фильтры</a
        >
      </p>
    </div>
    <div v-else-if="!filteredList.length">
      <p>
        Список профилей пуст. Мы уточняем программу олимпиады. Профили появятся
        позже.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uniqBy from "lodash/uniqBy";
import ProfileCard from "@/components/profile/ProfileCard";
export default {
  name: "MentorProfiles",
  components: { ProfileCard },
  metaInfo() {
    return {
      title: `Все профили НТО`,
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      search: "",
      selectedTracks: [],
      selectedRoutes: [],
      pendingProfiles: {},
      initialFavoriteProfiles: [],
    };
  },
  computed: {
    ...mapGetters({
      allProfiles: "profile/allProfiles",
    }),
    favoriteProfiles() {
      return Object.keys(this.$store.state.mentor.profiles).map(Number);
    },

    list() {
      const { initialFavoriteProfiles } = this;
      return Object.values(this.allProfiles)
        .map((profile) => {
          return {
            ...profile,
            profileType: profile.is_special_project ? "Спецпроект" : "Профиль",
            _searchTitle: profile.title.toLowerCase(),
            _order: initialFavoriteProfiles.includes(profile.id),
          };
        })
        .sort((a, b) => {
          return b._order - a._order;
        });
    },
    filteredList() {
      const { search, list, selectedTracks, selectedRoutes } = this;
      let result = list;
      if (search) {
        const low = search.toLowerCase();
        result = result.filter((p) => {
          return p._searchTitle.includes(low);
        });
      }

      const selectedRoutesIds = selectedRoutes.map((n) => n.id);
      const selectedTracksIds = selectedTracks.map((n) => n.id);

      if (selectedRoutesIds.length) {
        result = result.filter((p) => {
          return p.routes?.some((n) => selectedRoutesIds.includes(n.id));
        });
      }
      if (selectedTracksIds.length) {
        result = result.filter((p) => {
          return p.tracks?.some((n) => selectedTracksIds.includes(n.id));
        });
      }

      return result;
    },
    hasFilters() {
      const { search, selectedTracks, selectedRoutes } = this;
      return !!search || selectedTracks.length || selectedRoutes.length;
    },
    tracksOptions() {
      return Object.values(this.$store.state.tracks);
    },
    routesOptions() {
      const { allProfiles } = this;

      return uniqBy(
        allProfiles.reduce((acc, value) => {
          if (value.routes) {
            return [...acc, ...value.routes];
          }
          return acc;
        }, []),
        "id"
      ).sort((a, b) => {
        return b.title > a.title ? -1 : 1;
      });
    },

    selectedProfiles() {
      return this.$store.state.mentor.profiles;
    },
  },
  created() {
    /**
     * Для того чтобы избранные профили были наверху.
     * нельзя подписываться непосредственно на favoriteProfiles
     * потому, что после добавления в избранное,
     * применится новая сортировка.
     */
    this.initialFavoriteProfiles = [...this.favoriteProfiles];
  },
  methods: {
    dropFilters() {
      this.search = "";
      this.selectedTracks = [];
      this.selectedRoutes = [];
    },
    async handleRefuseProfile(profile_id) {
      const { selectedProfiles } = this;
      // если профиль выбран
      if (selectedProfiles[profile_id]) {
        this.$set(this.pendingProfiles, profile_id, true);
        try {
          await this.$store.dispatch("mentor/rejectProfile", profile_id);
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
        this.$set(this.pendingProfiles, profile_id, false);
      }
    },
    async handleSelectProfile(profile_id) {
      const { selectedProfiles } = this;
      // если профиля нет в выбранных
      if (!selectedProfiles[profile_id]) {
        this.$set(this.pendingProfiles, profile_id, true);
        try {
          await this.$store.dispatch("mentor/selectProfile", profile_id);
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
        this.$set(this.pendingProfiles, profile_id, false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.select-reset {
  padding-top: 0.2em;
  text-align: right;
}
.btn-inner {
  display: inline-flex;
  align-items: center;
  .base-icon {
    margin-left: 10px;
  }
}
</style>
