<template>
  <div
    class="p-card"
    :data-profile-id="id">
    <div class="p-card__header">
      <div class="p-card__icon">
        <BaseImage
          v-if="icon"
          :src="icon" />
        <BaseImage
          v-else
          src="@/assets/images/profile_placeholder.svg" />
      </div>
      <div class="p-card__info">
        <div
          v-if="label"
          class="p-card__label">
          {{ label }}
        </div>
        <router-link
          v-if="linkTo"
          class="p-card__title"
          :to="linkTo"
          >{{ title }}</router-link
        >
        <div
          v-else
          class="p-card__title">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="p-card__body">
      <div><slot></slot></div>
      <ul
        v-if="tags && tags.length"
        class="p-card__tags">
        <li
          v-for="tag in tags"
          :key="tag.id"
          class="p-card__tags-item">
          <AppTag>{{ tag.title }}</AppTag>
        </li>
      </ul>

      <div class="p-card__other-info">
        <div v-if="rsoshLevel">
          Уровень РСОШ&nbsp;&mdash;&nbsp;{{ rsoshLevel }}&emsp;
        </div>
        <a
          v-if="detailUrl"
          title="Подробнее на сайте НТО"
          :href="detailUrl"
          class="p-card__link link"
          target="_blank"
          rel="noopener noreferrer">
          <span class="p-card__link-text">{{ moreLabel }}</span>
          <BaseIcon
            glyph="link"
            class="p-card__link-icon" />
        </a>
      </div>
    </div>
    <div
      v-if="!!$slots['footer']"
      class="p-card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import AppTag from "@/components/AppTag";
export default {
  name: "ProfileCard",
  components: {
    AppTag,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    tags: {
      type: Array,
      validator(value) {
        return (
          value &&
          value.every((n) => {
            return n.id && n.title;
          })
        );
      },
    },
    linkTo: {
      type: [String, Object],
    },
    detailUrl: {
      type: String,
    },
    rsoshLevel: {
      type: Number,
    },
  },
  computed: {
    isJunior() {
      return this.$store.getters["participant/isJunior"];
    },
    moreLabel() {
      return this.isJunior ? "Больше про сферу" : `Больше про ${this.label}`;
    },
  },
};
</script>

<style lang="less" scoped>
@p-card-padding: 24px;
@p-card-padding-small: 12px;
.p-card {
  background-color: #fff;
  overflow: hidden;
  border-radius: @radius-xl;
  box-shadow: @shadow-default;
  display: flex;
  flex-direction: column;

  &__body {
    padding: @p-card-padding;
    flex-grow: 1;
    .fz(14);

    .media-max-xs({
      padding: @p-card-padding-small ;
    });
  }

  &__header {
    padding: @p-card-padding @p-card-padding 0 @p-card-padding;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 0;

    .media-max-xs({
      padding: @p-card-padding-small @p-card-padding-small 0 @p-card-padding-small ;
    });
  }

  &__info {
    flex-grow: 1;
    padding-top: 10px;
    min-width: 0;
    .media-max-xs({padding-top: 0;});
  }

  &__icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: @tropical-blue;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 15px;
    position: relative;
    border: 2px solid #fff;
    box-shadow: 0px 0px 3px @tropical-blue;
    overflow: hidden;
    .media-max-xs({
      width: 40px;
      height: 40px;
    });
  }

  &__title {
    .fz(@font-size-h3);
    line-height: 1.2;
    font-weight: bold;
    text-decoration: none;
    color: inherit;
    .media-max-xs({
      .fz(@font-size-m);
    });
  }

  &__label {
    .fz(@font-size-xs);
  }

  &__tags {
    list-style-type: none;
    font-size: @font-size-xs;
    margin-top: 1.5em;
    margin-bottom: -0.95em;

    &-item {
      display: inline-block;
      margin-right: 0.95em;
      margin-bottom: 0.95em;
    }
  }

  &__footer {
    padding: 0 @p-card-padding @p-card-padding;
    flex-grow: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: 600;

    &-text {
      text-transform: lowercase;
    }

    &-icon {
      margin-left: 10px;
    }
  }

  &__other-info {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
  }
}
</style>
